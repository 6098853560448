<div class="article-header-container" *ngIf="_article">
  <div class="article-header-top-bar">
    <div class="article-header-tags-container">
      <span
        *ngFor="let category of _article.categories"
        class="article-header-label"
        [ngStyle]="{ color: category.color }"
      >
        {{ category.name | uppercase }}
      </span>
      <a
        *ngFor="let tag of _article.tags"
        class="article-header-label"
        (click)="searchByTagName(tag.name)"
      >
        {{ tag.name | uppercase }}
      </a>
    </div>
    <div class="article-header-controls-container">
      <button
        class="article-header-control-item"
        *ngIf="(isLoggedIn$ | async) && !isFavorite(_article.id)"
        (click)="addToFavorite()"
      >
        <p class="article-header-control-text">Dodaj do ulubionych</p>
        <span class="favorite-icon material-icons">favorite_border</span>
      </button>
      <button
        class="article-header-control-item"
        *ngIf="(isLoggedIn$ | async) && isFavorite(_article.id)"
        (click)="removeFromFavorite()"
      >
        <p class="article-header-control-text">Usuń z ulubionych</p>
        <span class="favorite-icon favorite-icon-remove material-icons"
          >favorite</span
        >
      </button>
      <!-- <button *ngIf="!isProduction()" class="article-header-control-item" (click)="downloadPdfRequest(downloadArrow)">
        <p class="article-header-control-text">Pobierz PDF</p>
        <span #downloadArrow class="arrow-down material-icons">trending_flat</span>
      </button> -->
    </div>
  </div>
  <h1 class="article-header-title" [innerHtml]="_article.title"></h1>
  <div *ngIf="_article.editor" class="article-header-redactor-container">
    <img
      *ngIf="_article.editor.img && _article.editor.img.url"
      [strapiImageDesiredFormat]="imageAuthorFormat"
      [strapiImage]="_article.editor.img"
      class="article-header-editor-image"
    />
    <div>{{ _article.editor.name }}, {{ articleDisplayDate }}</div>
  </div>
  <div *ngIf="!_article.editor" class="article-header-redactor-container">
    <div>{{ articleDisplayDate }}</div>
  </div>
  <div class="article-header-image-container">
    <img
      class="article-header-article-image"
      [alt]="_article?.title"
      *ngIf="_article.img && _article.img.url"
      [strapiImageDesiredFormat]="imageArticleFormat"
      [strapiImage]="_article.img"
      width="1000"
      height="600"
    />
  </div>
</div>
