<div *ngIf="isBrowser()" class="bookstore-container">
  <div class="header-container">
    <h3>{{ component.title }}</h3>
    <div class="bookstore-logo">
      <a
        href="{{ component.bookstoreUrl }}"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="{{ logoSrc }}"
          [alt]="component?.title"
          loading="lazy"
          width="208"
          height="52"
        />
      </a>
    </div>
    <div class="bookstore-description">
      <span>{{ component.shortDescription }}</span>
    </div>
    <div class="go-to-bookstore-container">
      <a
        href="{{ component.bookstoreUrl }}"
        class="go-to-bookstore"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ component.goToBookstoreLabel }}
        <span class="material-icons arrow-icon">trending_flat</span>
      </a>
    </div>
  </div>
  <div class="promoted-books-container">
    <p-carousel
      [value]="books"
      [numVisible]="5"
      [numScroll]="1"
      styleClass="p-carousel p-widget p-carousel-horizontal"
      *ngIf="initialized"
    >
      <!-- property styleClass is required to fix bug on build dev mode ...ECH!!! -->
      <ng-template let-book pTemplate="item">
        <div class="book-container">
          <div class="book">
            <a href="{{ book.link }}" target="_blank" rel="noopener noreferrer">
              <img
                *ngIf="book.images.length > 0"
                [strapiImageDesiredFormat]="imageFormat"
                [strapiImage]="book.images[0]"
                alt="{{ book.title }}"
                loading="lazy"
              />
            </a>
            <div class="book-info">
              <a
                href="{{ book.link }}"
                class="title"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>{{ book.title }}</p>
              </a>
              <p class="authors">{{ booksAuthors.get(book.id) }}</p>
              <a
                class="bookstore-link"
                href="{{ book.link }}"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Kup</span>
                <span class="material-icons arrow-icon">trending_flat</span>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
    <div class="button-container">
      <button>
        <a
          href="{{ component.bookstoreUrl }}"
          target="_blank"
          rel="noopener noreferrer"
          >{{ component.goToBookstoreLabel }}</a
        >
      </button>
    </div>
  </div>
</div>
