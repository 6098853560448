<img
  [style]="{
    width: component.width > 570 ? '570px' : component.width + 'px',
    height: component.height + 'px'
  }"
  [ngClass]="{ 'image-max-width': !component.width }"
  class="main-image"
  (click)="openGallery(galeryModal)"
  [strapiImageDesiredFormat]="imageFormat"
  [strapiImage]="component.images[0].img"
/>

<ng-template #galeryModal let-modal>
  <ngb-carousel *ngIf="imagesSrc" #carousel [interval]="0">
    <ng-template ngbSlide *ngFor="let image of imagesSrc">
      <div *ngIf="!showFullImage" class="carousel-wrapper">
        <div class="img-wrapper">
          <a href="{{ image?.link }}" target="_blank" rel="noopener noreferrer">
            <img src="{{ image?.url }}" alt="{{ image?.title }}" />
          </a>
          <div class="carousel-caption">
            <h5>{{ image?.title }}</h5>
            <p [innerHTML]="image?.description"></p>
          </div>
          <span id="prevClick" (click)="carousel.prev()"></span>
          <span id="nextClick" (click)="carousel.next()"></span>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
  <span class="material-icons close-gallery" (click)="closeModal()">clear</span>
</ng-template>
