<div class="article-author-content-container">
  <img
    *ngIf="author.img?.url"
    alt="{{ author.name }}"
    [strapiImage]="author.img"
    [strapiImageDesiredFormat]="imageFormat"
    class="author-img-container"
  />
  <div class="d-flex author-wrapper">
    <div class="author-info-container">
      <span class="author-label">Autor</span>
      <span class="author-name">{{ author.name }}</span>
      <span class="author-bio" [innerHTML]="author.bio"></span>
    </div>
    <div class="button-container">
      <button>
        <a [routerLink]="['/autor', authorSlug]"
          >Zobacz więcej artykułów tego autora</a
        >
      </button>
    </div>
  </div>
</div>
