import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/@shared/service/authentication/authentication.service';
import { Logger } from '@core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

const log = new Logger('ShellComponent');

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss', './shell.component.mobile.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  private isLoggInSubscription: Subscription;
  private canRedirectFromThisPath: boolean = false;
  private fillRegistrationData: boolean = false;

  urlWhereRegisterExistingNotAppear: string[] = [
    '/strona/5f33a477643341036688bf17',
    '/strona/5f33a477643341036688bf18',
  ];

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    log.debug('Component init started..');

    console.log('wersja UI 1.4.11');

    this.canRedirectFromThisPath = !this.urlWhereRegisterExistingNotAppear.find(
      (url) => this.router.url === url
    );
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.canRedirectFromThisPath = !this.urlWhereRegisterExistingNotAppear.find(
          (url) => event.url === url
        );
      }
    });
    this.isLoggInSubscription = this.authService.isLoggedIn$.subscribe(
      (value) => {
        this.fillRegistrationData =
          value && this.authService.credentials.fillRegistrationData;
      }
    );
  }

  showRegisterExistingForm() {
    return this.fillRegistrationData && this.canRedirectFromThisPath;
  }

  isRegisterEntry() {
    return this.router.url === '/rejestracja';
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.isLoggInSubscription?.unsubscribe();
  }
}
