<div class="logo-container">
  <a href="{{ partner.link }}" target="_blank" rel="noopener noreferrer">
    <img
      [strapiImageDesiredFormat]="imageFormat"
      [strapiImage]="partner.img"
      alt="{{ partner.name }}"
      class="logo-image"
      loading="lazy"
    />
  </a>
</div>
