import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import {
  getPwnException,
  PwnAgreements,
  PwnExceptionTypeEnum,
  PwnLoginTypeEnum,
  PwnUser,
  PwnUserChangePassword,
  PwnUserCredentials,
  PwnUserTenantEmail,
  PwnUserWithTokens,
} from '@nursing/pwn-cms-model/lib';
import { Router } from '@angular/router';

import { ConfigurationService } from '@app/@shared/service/configuration/configuration.service';
import { AuthenticationService } from '@app/@shared/service/authentication/authentication.service';
import { environment } from '@env/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { UserProductService } from '@app/@shared/service/user-product/user-product.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  passwordChangeForced: BehaviorSubject<boolean>;
  countryCodeSubject: BehaviorSubject<
    Map<string, string>
  > = new BehaviorSubject(null);
  countryCodeMap: Map<string, string> = new Map();
  timeout = false;

  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService,
    private authService: AuthenticationService,
    private router: Router,
    private state: TransferState,
    private userProductService: UserProductService
  ) {
    this.passwordChangeForced = new BehaviorSubject<boolean>(null);
  }

  login(form: any): Observable<PwnUserWithTokens> {
    const body: PwnUserCredentials = {
      tenantId: environment.tenantId,
      email: form.email,
      password: form.password,
    };

    return this.http.post<PwnUserWithTokens>('/pwn-user/login', body).pipe(
      tap((res) => {
        this.authService.updatePwnUserWithTokensSubject(res);
      })
    );
  }

  registerUser(
    form: any,
    agreements: PwnAgreements
  ): Observable<PwnUserWithTokens> {
    return this.configurationService.getConfiguration().pipe(
      flatMap((configuration) => {
        const body: PwnUser = {
          loginType: PwnLoginTypeEnum.Sso,
          tenantId: configuration.tenantId,
          email: form.email,
          password: form.password,
          occupationalGroup: form.group.name,
          occupationalSpecialization: form.specialization.name,
          pwzNumber: form.pwzNumber,
          firstName: form.firstName,
          lastName: form.lastName,
          countryCode: form.country.code,
          agreements: agreements.agreements.map((agreement) => {
            return {
              id: agreement.id,
              version: agreement.version,
              url: agreement.url,
            };
          }),
        };
        return this.http.post<PwnUserWithTokens>('/pwn-user/register', body);
      })
    );
  }

  updateUserRegistrationData(
    form: any,
    agreements: PwnAgreements
  ): Observable<PwnUserWithTokens> {
    return this.configurationService.getConfiguration().pipe(
      flatMap((configuration) => {
        const body: PwnUser = {
          loginType: PwnLoginTypeEnum.Sso,
          tenantId: configuration.tenantId,
          email: this.authService.credentials.email,
          occupationalGroup: form.group.name,
          occupationalSpecialization: form.specialization.name,
          pwzNumber: form.pwzNumber,
          firstName: form.firstName,
          lastName: form.lastName,
          countryCode: form.country.code,
          agreements: agreements.agreements.map((agreement) => {
            return {
              id: agreement.id,
              version: agreement.version,
              url: agreement.url,
            };
          }),
        };
        return this.http.post<PwnUserWithTokens>(
          '/pwn-user/register/update',
          body
        );
      })
    );
  }

  getUser(): Observable<PwnUser> {
    return this.authService.user$.pipe(
      take(1),
      map((res) => {
        if (this.authService.isAuthenticated()) {
          return res;
        } else {
          throw getPwnException(
            PwnExceptionTypeEnum.UnauthorizedException,
            'User is unauthorized'
          );
        }
      }),
      switchMap((res) => {
        const email = this.authService.credentials.email;
        const tenantId = this.authService.credentials.tenantId;
        const key = makeStateKey<PwnUser>(
          'userService_user_' + email + '_' + tenantId
        );
        const value: PwnUser = this.state.get<PwnUser>(key, null);

        if (value) {
          if (!this.timeout) {
            setTimeout(() => {
              this.state.set(key, null);
              this.timeout = false;
            }, 2000);
            this.timeout = true;
          }
          this.authService.updateUserSubject(value);
          return of(value);
        } else {
          return this.http
            .get<PwnUser>(`/pwn-user/user/${tenantId}/${email}`)
            .pipe(
              tap((res2) => {
                this.authService.updateUserSubject(res2);
                this.userProductService.updateUserFavoriteArticlesIds(
                  res2.favoriteProducts
                );
                this.state.set<PwnUser>(key, res2);
              })
            );
        }
      })
    );
  }

  updateUserAdditionalData(
    email: string,
    form: any
  ): Observable<PwnUserWithTokens> {
    return this.configurationService.getConfiguration().pipe(
      flatMap((configuration) => {
        const body: PwnUser = {
          loginType: PwnLoginTypeEnum.Sso,
          tenantId: configuration.tenantId,
          email,
          occupationalGroup: form.group.name,
          occupationalSpecialization: form.specialization.name,
          pwzNumber: form.pwzNumber,
          firstName: form.firstName,
          lastName: form.lastName,
          countryCode: form.countryCode.code,
        };

        const tenantId = this.authService.credentials.tenantId;
        const key = makeStateKey('userService_user_' + email + '_' + tenantId);
        this.state.set(key, null);

        return this.http.put<PwnUserWithTokens>('/pwn-user/user', body);
      })
    );
  }

  updateUserBillingData(email: string, form: any): Observable<PwnUser> {
    return this.configurationService.getConfiguration().pipe(
      flatMap((configuration) => {
        const body: PwnUser = {
          loginType: PwnLoginTypeEnum.Sso,
          tenantId: configuration.tenantId,
          email,
          billingData: {
            name: form.billingName,
            street: form.billingStreet,
            houseNumber: form.billingHouseNumber,
            apartmentNumber: form.billingApartmentNumber,
            city: form.billingCity,
            zip: form.billingPostalCode,
            nipNumber: form.billingNIPNumber,
          },
        };

        const tenantId = this.authService.credentials.tenantId;
        const key = makeStateKey('userService_user_' + email + '_' + tenantId);
        this.state.set(key, null);

        return this.http.put<PwnUser>('/pwn-user/user', body);
      })
    );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const body: PwnUserChangePassword = {
      oldPassword,
      newPassword,
    };

    const tenantId = this.authService.credentials.tenantId;
    const email = this.authService.credentials.email;
    const key = makeStateKey('userService_user_' + email + '_' + tenantId);
    this.state.set(key, null);
    return this.http.post('/pwn-user/user/change-password', body);
  }

  resetPassword(form: any): Observable<any> {
    return this.configurationService.getConfiguration().pipe(
      flatMap((configuration) => {
        if (!configuration) {
          return;
        }

        const body: PwnUserTenantEmail = {
          tenantId: configuration.tenantId,
          email: form.email,
        };
        return this.http.post('/pwn-user/user/reset-password', body);
      })
    );
  }

  deleteAccount(): Observable<any> {
    return this.configurationService.getConfiguration().pipe(
      flatMap((configuration) => {
        const email = this.authService.credentials.email;
        const tenantId = configuration.tenantId;

        const key = makeStateKey('userService_user_' + email + '_' + tenantId);
        this.state.set(key, null);

        return this.http.delete(`/pwn-user/user/${tenantId}/${email}`).pipe(
          tap((result) => {
            this.authService.logout();
            this.router.navigate(['/'], { replaceUrl: true });
          })
        );
      })
    );
  }

  getPasswordChangeForced() {
    return this.passwordChangeForced.asObservable();
  }

  setPasswordChangeForced(status: boolean): void {
    this.passwordChangeForced.next(status);
  }

  getCountryCodeMap(): BehaviorSubject<Map<string, string>> {
    if (this.countryCodeMap.size === 0) {
      this.http
        .get('/pwn-user/countries')
        .subscribe((val: { name: string; code: string }[]) => {
          val.forEach((v) => {
            this.countryCodeMap.set(v.code, v.name);
          });
          this.countryCodeSubject.next(this.countryCodeMap);
        });
    }
    return this.countryCodeSubject;
  }
}
