<div *ngIf="!isMobile" class="book-desktop-container">
  <div class="book-left-container">
    <div class="book-inner-container">
      <img
        class="book-image"
        [strapiImageDesiredFormat]="imageDesktopFormat"
        [strapiImage]="book.images[0]"
        alt="{{ book.title }}"
        [style]="'object-fit: ' + bookImageDisplayType"
        loading="lazy"
      />
    </div>
  </div>
  <div class="book-right-container">
    <a class="book-title">{{ book.title }}</a>
    <div class="purchase-button-container">
      <a
        class="purchase-button"
        target="_blank"
        rel="noopener noreferrer"
        href="{{ book.link }}"
      >
        Kup prenumeratę
      </a>
    </div>
  </div>
</div>

<div *ngIf="isMobile" class="book-mobile-container">
  <div class="book-title">{{ book.title }}</div>
  <div class="book-inner-container">
    <img
      class="book-image"
      [strapiImageDesiredFormat]="imageMobileFormat"
      [strapiImage]="book.images[0]"
      alt="{{ book.title }}"
      [style]="'object-fit: ' + bookImageDisplayType"
      loading="lazy"
    />
  </div>
  <div class="purchase-button-container">
    <a
      class="purchase-button"
      target="_blank"
      rel="noopener noreferrer"
      href="{{ book.link }}"
    >
      Kup prenumeratę
    </a>
  </div>
</div>
