import { env } from './.env';

export const environment = {
  production: true,
  hmr: false,
  version: env.npm_package_version,
  // serverUrl: 'http://pwn-dev-nginx:8083',
  serverUrl: 'https://nursing.com.pl',
  defaultLanguage: 'pl-PL',
  supportedLanguages: ['pl-PL'],
  CHROME_BIN: '/usr/bin/chromium',
  tenantId: 'nursing',
  provider: 'local',
};
