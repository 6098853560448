<div class="microservice-container">
  <div
    class="microservice-desktop-image"
    [style]="
      !imageSrc
        ? 'background-color: ' +
          (category.color ? category.color : defaultColor)
        : ''
    "
  >
    <a [routerLink]="['/artykuly', slugIdMap.get(category.id)]">
      <img
        *ngIf="imageSrc?.length > 0"
        src="{{ imageSrc }}"
        [ngClass]="imageSize"
        class="desktop-image"
        alt="Obrazek mikroserwisu"
        loading="lazy"
      />
    </a>
  </div>
  <div
    class="microservice-mobile-image"
    [style]="
      !imageSrc
        ? 'background-color: ' +
          (category.color ? category.color : defaultColor)
        : ''
    "
  >
    <a [routerLink]="['/artykuly', slugIdMap.get(category.id)]">
      <img
        *ngIf="mobileImageSrc?.length > 0"
        src="{{ mobileImageSrc }}"
        [ngClass]="imageSize"
        class="mobile-image"
        alt="Obrazek mikroserwisu"
        loading="lazy"
      />
    </a>
  </div>
  <a [routerLink]="['/artykuly', slugIdMap.get(category.id)]">
    <div class="name">
      {{ category.displayName }}
    </div>
  </a>
  <div class="description" [innerHTML]="category.description"></div>
  <div class="read-more-container">
    <a
      [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
      class="read-more"
    >
      {{ readMoreLabel }}
      <span class="material-icons arrow-icon">trending_flat</span>
    </a>
  </div>
</div>
